<template>
    <div id="ModuleTitle">
        <!-- 中文标题 -->
        <div class="title">{{ title }}</div>
        <!-- 英文标题 -->
        <div class="english-title">
            <span>{{ EnglishTitle }}</span>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '未定义标题'
    },
    EnglishTitle: {
      type: String,
      default: 'UNDEFINED TITLE'
    }
  }
}
</script>
<style lang="less" scoped>
    #ModuleTitle{
        width: 1200px;
        height: 175px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: bold;
        // letter-spacing: 2px;
        user-select: none;  //  不允许内容被选中
        // 中文标题
        & > .title{
            font-size: 30px;
            color: #2E67B1;
            z-index: 2;
            &::after{
                content: '-';
                padding-left: 20px;
            }
            &::before{
                content: '-';
                padding-right: 20px;
            }
        }
        // 英文标题
        & > .english-title{
            position: absolute;
            z-index: 1;
            margin-top: -27px;  //  向上移动文字一半大小的距离
            letter-spacing: 1px;
            & > span{
                font-size: 54px;
                color: #F2F2F2;
            }
        }
    }
</style>
